import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "village",
    component: () => import("@/views/village.vue"),
  },
  {
    path: "/meetingDetail",
    name: "meetingDetail",
    component: () => import("@/views/meetingDetail.vue"),
  },
  {
    path: "/village",
    name: "village",
    component: () => import("@/views/village.vue"),
  },
  {
    path: "/dynamic",
    name: "dynamic",
    component: () => import("@/views/dynamic.vue"),
  },
  {
    path: "/industry",
    name: "industry",
    component: () => import("@/views/industry.vue"),
  },
  {
    path: "/character",
    name: "character",
    component: () => import("@/views/character.vue"),
  },
  {
    path: "/survey",
    name: "survey",
    component: () => import("@/views/survey.vue"),
  },
  // 动态无用
  {
    path: "/screenThird",
    name: "screenThird",
    component: () => import("@/views/screenThird.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
