import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "vant/lib/index.css";
import "amfe-flexible";
import dataV from "@jiaminghi/data-view";
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)
import {
  Swipe,
  SwipeItem,
  Toast,
  Search,
  List,
  PullRefresh,
  Dialog,
  Field,
} from "vant";
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Toast);
Vue.use(Search);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Dialog);
Vue.use(Field);
Vue.use(dataV);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
