<template>
  <div id="app">
    <keep-alive include="ordervList">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>
<script>
export default {
  name: "app",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {},
};
</script>
<style lang="scss">
@import "assets/style/atom.scss";
</style>
